$navbarHeight: 64px;
$toolbarHeight: 64px;

.ReportConfigEditor {
  padding-top: 64px;

  &--main {
    min-height: calc(100vh - #{$navbarHeight + $toolbarHeight});
    overflow-y: auto;
  }
}

.ReportConfigEditorToolbar {
  border-bottom: 1px solid #ddd;
  background: white;
  height: $toolbarHeight;

  &--header {
    flex: 0 0 204px !important;
  }
}

.ReportConfigEditorSidebar {
  height: calc(100vh - #{$navbarHeight + $toolbarHeight});
  overflow-y: auto;
  flex: 0 0 240px;
  width: 240px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;

  &--divider {
    background: #f0f2f5;
    border-bottom: 1px solid #ddd;
  }

  &--listItem {
    position: relative;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;

    .warning {
      @apply text-danger;
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 8px;
    }

    &.selected {
      background-color: #19bf820d;
    }

    &.empty {
      color: #c1c1c1;
    }
  }
}

.ReportConfigEditorContent {
  position: relative;
  height: calc(100vh - #{$navbarHeight + $toolbarHeight});
  overflow-y: auto;

  .CodeMirror {
    height: 40vh;
  }

  &.overflowHidden {
    overflow: hidden;
  }
}

.ReportConfigEditorPreview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;

  &--content {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  iframe {
    border: 0;
    width: 100%;
    height: calc(100vh - 230px);
    float: left;
  }
}
