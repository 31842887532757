@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f0f2f5;
  margin: 0;
  padding: 0;
  font-size: theme(fontSize.base);
}

hr {
  border-width: 0.03vw !important;
}

pre {
  white-space: pre-line;
}

.clickable {
  cursor: pointer;
}

input,
textarea {
  color: black;
}

input:disabled {
  color: #989999;
}

.Loader > .Loader--wrapper > .Loader--indicator {
  display: flex;
  margin: 0 auto;
}
