$navbarHeight: 64px;
$toolbarHeight: 90px;
$listHeadHeight: 32px;

.ReportConfigQueues {
  padding-top: 64px;

  &--main {
    min-height: calc(100vh - #{$navbarHeight + $toolbarHeight});
    overflow-x: hidden;
  }

  &--sidebar {
    flex: 0 0 200px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.ReportConfigQueuesToolbar {
  border-bottom: 1px solid #ddd;
  background: white;
  height: $toolbarHeight;

  &--header {
    flex: 0 0 204px !important;
  }

  &--search {
    z-index: 1;
    flex: 0 0 300px;
  }

  &--count {
    flex: 0 0 auto;
  }
}

.ReportConfigQueuesTabs {
  &--tab {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15) inset;
    margin-top: -1px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    background: #fff;
    text-transform: capitalize;

    &.selected {
      background-color: #19bf8226;
    }

    &:last-child {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15) inset,
        0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    }
  }
}

.ReportConfigQueuesList {
  position: relative;
  background: #fff;
  z-index: 1;

  &--head {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @apply bg-lightGray;
  }

  &--result {
    background: white;
    flex: 1;
    max-height: calc(
      100vh - #{$navbarHeight + $toolbarHeight + $listHeadHeight}
    );
    overflow-y: auto;
    overflow-x: hidden;

    .loadingMore {
      max-height: calc(
        100vh - #{$navbarHeight + $toolbarHeight + $listHeadHeight + 60px}
      );
    }
  }

  &--row {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;

    &:nth-child(even) {
      background: rgba(240, 242, 245, 0.4);
    }

    &:last-child {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: #19bf820d;
    }
  }

  .ReactVirtualized__List {
    outline: none;
  }
}
