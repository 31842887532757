/* since most of view has its own scroll area.*/
body {
  overflow: hidden;
}

/* for react-router-transition */
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  min-height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
}

.flex {
  > .basis-125 {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }

  > .basis-15 {
    flex: 0 0 15% !important;
    max-width: 15% !important;
  }

  > .basis-35 {
    flex: 0 0 35% !important;
    max-width: 35% !important;
  }
}
