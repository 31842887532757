.RecentEngageTransaction table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}
